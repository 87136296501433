import React from "react";
import "./gallery.css";

function Gallery() {
  return (
    <div className="gallery">
      <div className="img-container">
        <img src="https://i.imgur.com/OjRHVqQ.jpeg" alt="Feta Avocado Toast" className="gallery-img" />
        <h4 className="food-name">Feta Avocado Toast</h4>
      </div>
      <div className="img-container">
        <img src="https://i.imgur.com/iy0KUCb.jpeg" alt="Tuna Salad Sandwich" className="gallery-img" />
        <h4 className="food-name">Tuna Salad Sandwich</h4>
      </div>
      <div className="img-container">
        <img src="https://i.imgur.com/DhcNjoL.jpeg" alt="Smoked Salmon Avocado Toast" className="gallery-img" />
        <h4 className="food-name">Smoked Salmon Avocado Toast</h4>
      </div>
      <div className="img-container">
        <img src="https://i.imgur.com/GCGMeu0.jpeg" alt="Menemen(Shakshouka)" className="gallery-img" />
        <h4 className="food-name">Menemen(Shakshouka)</h4>
      </div>
      <div className="img-container">
        <img src="https://i.imgur.com/6H20p0O.jpeg" alt="Smoked Salmon Bagel" className="gallery-img" />
        <h4 className="food-name">Smoked Salmon Bagel</h4>
      </div>
      <div className="img-container">
        <img src="https://i.imgur.com/Iyt2c7E.jpeg" alt="Bacon Egg & Cheddar Croissant" className="gallery-img" />
        <h4 className="food-name">Bacon Egg & Cheddar Croissant</h4>
      </div>
      {/* <div className="img-container">
        <img src="" alt="" className="gallery-img" />
        <h4 className="food-name"></h4>
      </div> */}
    </div>
  );
}

export default Gallery;
