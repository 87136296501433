import React, { useEffect } from "react";
import "./extra.css";
import Lottie from "lottie-react";
import Lights from "../../Assets/lights.json";
import Merry from "../../Assets/merry.json";

function Extra() {
  useEffect(() => {
    const handleScroll = () => {
      const elements = document.querySelectorAll(".fade-scroll");
      elements.forEach((el) => {
        const rect = el.getBoundingClientRect();
        if (rect.top < window.innerHeight * 0.9) {
          el.classList.add("visible");
        }
      });
    };

    window.addEventListener("scroll", handleScroll);
    handleScroll();
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <div className="extras fade-scroll">
      <div className="gift-card fade-scroll">
        <h1 className="gift-card-header fade-scroll">
          Ring in the Valentine's Day by Sharing the Gift of Great Coffee!
        </h1>
        <div className="gift-card-inside">
          <Lottie 
            animationData={Merry}
            loop
          />
          <p>
            Treat your loved ones to the perfect brew with a gift card from Cafe
            Vista. A thoughtful way to make their day—or even their whole
            month—filled with warmth and flavor!
          </p>
        </div>
      </div>
      <div className="punch-card fade-scroll">
        <h1 className="punch-card-header fade-scroll">
          Punch Cards Available!
        </h1>
        <h4>Buy 10 Drinks and Get 1 Free!</h4>
        <img
          src="https://i.imgur.com/RjczW6S.png"
          className="punch-card-img fade-scroll"
          alt="punchcard"
        />
      </div>
    </div>
  );
}

export default Extra;
