import React, { useState, useEffect } from "react";
import "./navigator.css";
import { Link } from "react-router-dom";
import { Squeeze as Hamburger } from "hamburger-react";

function Navigator() {
  const [isOpen, setOpen] = useState(false);

  useEffect(() => {
    if (isOpen) {
      document.body.classList.add("no-scroll");
    } else {
      document.body.classList.remove("no-scroll");
    }

    return () => {
      document.body.classList.remove("no-scroll");
    };
  }, [isOpen]);

  return (
    <div className="navigator">
      <Hamburger toggled={isOpen} toggle={setOpen} easing="ease-in" />
      {isOpen && (
        <div className="menu">
          <Link to="/menu" onClick={() => setOpen(false)}>
            MENUS
          </Link>
          <Link to="/aboutus" onClick={() => setOpen(false)}>
            ABOUT US
          </Link>
        </div>
      )}
    </div>
  );
}

export default Navigator;
