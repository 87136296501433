import "./swipermanual.css";
import React from "react";
import img1 from "../../Assets/avotoast.JPG";
import img2 from "../../Assets/swiper2.jpg";
import useEmblaCarousel from "embla-carousel-react";
import Autoplay from "embla-carousel-autoplay";
import { Link } from "react-router-dom";

function SwiperGallery() {
  const [emblaRef] = useEmblaCarousel(
    { loop: true },
    [Autoplay({ delay: 4000 })] 
  );

  return (
    <div className="embla" ref={emblaRef}>
      <div className="embla__container">
        <div className="embla__slide">
          <img src={img1} alt="avotoast and rose latte" className="swiper-photos" />
          <h4 className="header-swiper">Breakfast & Coffee</h4>
          <p className="p-swiper">Explore our menu and discover delicious foods, specialty drinks, and homemade pastries.</p>
          <Link to="/menu">
          <button type="button" className="button-swiper">See Menus</button>
          </Link>
        </div>
        <div className="embla__slide">
          <img src={img2} alt="iced coffee" className="swiper-photos" />
          <h4 className="header-swiper">Serving Hoboken Since 2020</h4>
          <p className="p-swiper">Take a moment to read our story and discover more about our journey, our passion, and what makes us who we are.</p>
          <Link to="/aboutus">
          <button type="button" className="button-swiper">Our Story</button>
          </Link>
        </div>
        <div className="embla__slide">
          <img src="https://i.imgur.com/lZW4xD4.jpeg" alt="iced coffee" className="swiper-photos" />
          <h4 className="header-swiper">Visit Dream Vista</h4>
          <p className="p-swiper">Visit Dream Vista, our Jersey City location, and explore its beautifully designed indoor and outdoor spaces.</p>
          <Link to="https://maps.app.goo.gl/TpXa1WuEhotGh88A7" target="_blank">
          <button type="button" className="button-swiper">Dream Vista</button>
          </Link>
        </div>
      </div>
    </div>
  );
}

export default SwiperGallery;
