import React, { useEffect, useRef } from "react";
import "./landing.css";
import Header from "../../Components/Header/Header.jsx";
import Swiper from "../../Components/Swiper/Swiper.jsx";
import Navigator from "../../Components/Navigator/Navigator.jsx";
import Extra from "../../Components/Extras/Extra.jsx";
import Footer from "../../Components/Footer/Footer.jsx";

function Landing() {
  const footerRef = useRef(null);

  useEffect(() => {
    const handleScroll = () => {
      const elements = document.querySelectorAll(".fade-scroll");
      elements.forEach((el) => {
        const rect = el.getBoundingClientRect();
        if (rect.top < window.innerHeight * 0.9) {
          el.classList.add("visible");
        }
      });
    };

    window.addEventListener("scroll", handleScroll);
    handleScroll();
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const scrollToFooter = () => {
    footerRef.current.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <div>
      <div className="fade-scroll header">
        <Header />
      </div>
      <div className="fade-scroll swiper-container">
        <Swiper />
      </div>
      <div className="fade-scroll">
        <Extra />
      </div>
      <div className="fade-scroll" ref={footerRef}>
        <Footer />
      </div>
    </div>
  );
}

export default Landing;
