import React from "react";
import "./menu.css";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import Header from "../../Components/Header/Header.jsx";
import Footer from "../../Components/Footer/Footer.jsx"
import { Link } from "react-router-dom";
import Gallery from "../../Components/Gallery/Gallery";

function Menu() {
  return (
    <div>
      <div className="header-for-menu">
      <Link to="/">
        <Header />
      </Link>
      </div>
      <div>
        <h2 className="menu-h2">MENUS</h2>
      </div>
      <div>
        <Tabs>
          <TabList>
            <Tab>GALLERY</Tab>
            <Tab>FOODS</Tab>
            <Tab>DRINKS</Tab>
            <Tab>PASTRIES</Tab>
          </TabList>
          <TabPanel>
            <Gallery />
          </TabPanel>
          <TabPanel>
            <div className="food-menu">
              <h4 className="section">Avocado Toasts</h4>
              <p className="section-description">
                All Avocado Toast Served on Toasted Sour Dough Bread
              </p>
              <div className="food-box">
                <p className="item-name">Feta Avocado Toast</p>
                <p className="price">$12</p>
              </div>
              <div className="food-box">
                <p className="item-name">Smoked Salmon Avocado Toast</p>
                <p className="price">$13</p>
              </div>
              <div className="food-box">
                <p className="item-name">Poached Egg Avocado Toast</p>
                <p className="price">$13</p>
              </div>
              <div className="food-box">
                <p className="item-name">Hummus Avocado Toast</p>
                <p className="price">$12</p>
              </div>
              <h4 className="section">Bagels</h4>
              <p className="section-description">
                Your Choice of Plain or Everything Bagel
              </p>
              <div className="food-box">
                <p className="item-name">Cream Cheese Bagel</p>
                <p className="price">$6</p>
              </div>
              <div className="food-box">
                <p className="item-name">Smoked Salmon & Cream Cheese Bagel</p>
                <p className="price">$10</p>
              </div>
              <div className="food-box">
                <p className="item-name">Turkey Bacon, Egg & Cheddar Bagel</p>
                <p className="price">$10</p>
              </div>
              <h4 className="section">Omeletes</h4>
              <p className="section-description">
                All Omeletes Served with Toasted Sour Dough Bread
              </p>
              <div className="food-box">
                <p className="item-name">Sausage & Cheddar Omelette</p>
                <p className="price">$14</p>
              </div>
              <div className="food-box">
                <p className="item-name">Mushroom & Cheddar Omelette</p>
                <p className="price">$14</p>
              </div>
              <div className="food-box">
                <p className="item-name">Spinach & Feta Omelette</p>
                <p className="price">$14</p>
              </div>
              <h4 className="section">Menemen(Shakshouka)</h4>
              <div className="food-box">
                <p className="item-name">
                  Eggs in Tomato Sauce with Red & Green Peppers
                </p>
                <p className="price">$15</p>
              </div>
              <h4 className="section">Sandwiches</h4>
              <p className="section-description">Served in a Croissant</p>
              <div className="food-box">
                <p className="item-name">Egg & Cheese</p>
                <p className="price">$8</p>
              </div>
              <div className="food-box">
                <p className="item-name">Tuna Salad</p>
                <p className="price">$9</p>
              </div>
              <div className="food-box">
                <p className="item-name">Turkey Bacon, Egg & Cheddar</p>
                <p className="price">$10</p>
              </div>
              <div className="food-box">
                <p className="item-name">Sausage & Scrambled Eggs</p>
                <p className="price">$10</p>
              </div>
              <h4 className="section">Paninis</h4>
              <p className="section-description">Made with Sour Dough Bread</p>
              <div className="food-box">
                <p className="item-name">Grilled Cheese, Avocado & Tomato</p>
                <p className="price">$10</p>
              </div>
              <div className="food-box">
                <p className="item-name">Fresh Mozzarella, Pesto & Tomato</p>
                <p className="price">$10</p>
              </div>
              <h4 className="section">Vista Salad</h4>
              <div className="food-box">
                <p className="item-name">Greens, Avocado, Tomato & Feta</p>
                <p className="price">$8</p>
              </div>
            </div>
          </TabPanel>
          <TabPanel>
            <div className="drink-menu">
              <h4 className="section">Special Drinks</h4>
              <div className="food-box">
                <p className="item-name">Pistachio Latte</p>
                <p className="price">$6.5/$7.5</p>
              </div>
              <div className="food-box">
                <p className="item-name">Rose Latte</p>
                <p className="price">$6/$7</p>
              </div>
              <div className="food-box">
                <p className="item-name">Lavender Latte</p>
                <p className="price">$6/$7</p>
              </div>
              <div className="food-box">
                <p className="item-name">Turkish Coffee</p>
                <p className="price">$7</p>
              </div>
              <div className="food-box">
                <p className="item-name">Dalgona Coffee</p>
                <p className="price">$7/$8</p>
              </div>
              <div className="food-box">
                <p className="item-name">Sprinkle Peppermint Mocha</p>
                <p className="price">$7/$8</p>
              </div>
              <div className="food-box">
                <p className="item-name">Caramel Apple Butter Latte</p>
                <p className="price">$6/$7</p>
              </div>
              <div className="food-box">
                <p className="item-name">Hazelnut Cream Iced Coffee</p>
                <p className="price">$6/$7</p>
              </div>
              <div className="food-box">
                <p className="item-name">Turmeric Latte</p>
                <p className="price">$5/$6</p>
              </div>
              <div className="food-box">
                <p className="item-name">Tahini Hot Chocolate</p>
                <p className="price">$5/$6</p>
              </div>
              <h4 className="section">Vista Classics</h4>
              <div className="food-box">
                <p className="item-name">Drip Coffee</p>
                <p className="price">$3.5/$4.5</p>
              </div>
              <div className="food-box">
                <p className="item-name">Americano</p>
                <p className="price">$4.5/$5.5</p>
              </div>
              <div className="food-box">
                <p className="item-name">Espresso</p>
                <p className="price">$2.5/$3.5</p>
              </div>
              <div className="food-box">
                <p className="item-name">Latte</p>
                <p className="price">$5/$6</p>
              </div>
              <div className="food-box">
                <p className="item-name">Cappuccino</p>
                <p className="price">$5/$6</p>
              </div>
              <div className="food-box">
                <p className="item-name">Flat White</p>
                <p className="price">$4.5</p>
              </div>
              <div className="food-box">
                <p className="item-name">Cortado</p>
                <p className="price">$4.5</p>
              </div>
              <div className="food-box">
                <p className="item-name">Chai Latte</p>
                <p className="price">$4.5/$5.5</p>
              </div>
              <div className="food-box">
                <p className="item-name">Matcha Latte</p>
                <p className="price">$5.5/$6.5</p>
              </div>
              <div className="food-box">
                <p className="item-name">Hot Tea</p>
                <p className="price">$4/$5</p>
              </div>
              <div className="food-box">
                <p className="item-name">Iced Coffee</p>
                <p className="price">$4.5/5.5</p>
              </div>
              <div className="food-box">
                <p className="item-name">Cold Brew</p>
                <p className="price">$6/$7</p>
              </div>
              <div className="food-box">
                <p className="item-name">Iced Tea</p>
                <p className="price">$3.5/$4.5</p>
              </div>
              <h4 className="section">Milk Alternatives</h4>
              <div className="food-box">
                <p className="item-name">
                  Oat, Sugar Free Almond, Soy, Coconut, Lactose Free
                </p>
                <p className="price">$0.5</p>
              </div>
            </div>
          </TabPanel>
          <TabPanel>
            <div className="pastry-menu">
            <h4 className="section">Croissants</h4>
              <div className="food-box">
                <p className="item-name">Butter, Chocolate, Almond</p>
                <p className="price">$4.5</p>
              </div>
            <h4 className="section">Pastries</h4>
              <div className="food-box">
                <p className="item-name">Blueberry & Cheese Danish</p>
                <p className="price">$4.5</p>
              </div>
              <div className="food-box">
                <p className="item-name">Scone</p>
                <p className="price">$4.5</p>
              </div>
              <h4 className="section">Savories</h4>
              <div className="food-box">
                <p className="item-name">Everything Croissant with Feta Cheese</p>
                <p className="price">$4.5</p>
              </div>
              <div className="food-box">
                <p className="item-name">Cheddar & Scallion Scone</p>
                <p className="price">$4.5</p>
              </div>
              <div className="food-box">
                <p className="item-name">Chili Cheese Palmier</p>
                <p className="price">$4.5</p>
              </div>
              <div className="food-box">
                <p className="item-name">Spinach & Feta Roll</p>
                <p className="price">$9</p>
              </div>
              <h4 className="section">Homemade Pastries</h4>
              <div className="food-box">
                <p className="item-name">Gluten Free Brownie</p>
                <p className="price">$4.5</p>
              </div>
              <div className="food-box">
                <p className="item-name">Muffins</p>
                <p className="price">$4</p>
              </div>
              <div className="food-box">
                <p className="item-name">Pistachio & Chocolate Cookie</p>
                <p className="price">$5</p>
              </div>
            </div>
          </TabPanel>
        </Tabs>
      </div>
      <Footer />
    </div>
  );
}

export default Menu;
