import React from 'react'
import "./header.css"
import logo from "../../Assets/logo2.PNG"
import Navigator from '../Navigator/Navigator.jsx'

function Header() {
  return (
    <div className='heaeder'>
        <Navigator />
        <img src={logo} alt='logo' className='logo-for-header'/>
    </div>
  )
}

export default Header