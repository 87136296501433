import React, { useState } from "react";
import "./footer.css";
import { Link } from "react-router-dom";
import video1 from "../../Assets/womanowned.mp4";

const Footer = () => {
  const [openSection, setOpenSection] = useState(null);

  const toggleSection = (index) => {
    setOpenSection(openSection === index ? null : index);
  };

  const sections = [
    {
      title: "Hours",
      items: ["Monday & Tuesday 8 AM - 4 PM", "Wednesday - Sunday 8 AM - 5 PM"],
    },
    {
      title: "Locations",
      items: [
        <a
          href="https://maps.app.goo.gl/HDc5yw4MjZLnqdhs6"
          target="_blank"
          className="locations-href"
          rel="noreferrer"
        >
          527 Monroe St, Hoboken
        </a>,
        <a
          href="https://maps.app.goo.gl/TpXa1WuEhotGh88A7"
          target="_blank"
          className="locations-href"
          rel="noreferrer"
        >
          177 York St, Jersey City(Dream Vista)
        </a>,
      ],
    },
    {
      title: "Follow Us",
      items: [
        <a
          href="https://www.instagram.com/cafevistahoboken/?hl=en"
          target="_blank"
          className="locations-href"
          rel="noreferrer"
        >
          Instagram
        </a>,
      ],
    },
    {
      title: "About Us",
      items: [<Link to="/aboutus">Our Story</Link>],
    },
  ];

  return (
    <footer>
      <div className="footer-container">
        {sections.map((section, index) => (
          <div className="footer-section" key={index}>
            <h3 onClick={() => toggleSection(index)}>
              {section.title} <span>{openSection === index}</span>
            </h3>
            <ul className={openSection === index ? "open" : ""}>
              {section.items.map((item, i) => (
                <li key={i}>{item}</li>
              ))}
            </ul>
          </div>
        ))}
      </div>
      <div className="video-container">
        <video src={video1} autoPlay muted className="video" playsInline />
      </div>
      <div className="site-credits">
        <a
          href="https://github.com/zackcinal"
          target="_blank"
          rel="noreferrer"
          className="site-credits"
        >
          Site Credits
        </a>
      </div>
    </footer>
  );
};

export default Footer;
